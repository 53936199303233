import { graphql, useStaticQuery } from 'gatsby'
import { HomeSection } from '../../interfaces/home'

export default (): HomeSection => {
  const data = useStaticQuery(graphql`
    {
      strapiHome {
        create_organization {
          button_navigate_en
          button_navigate_fr
          button_navigate_mg
          description_en
          description_fr
          description_mg
          id
          image_illustration {
            publicURL
          }
          link
          title_en
          title_fr
          title_mg
        }
      }
    }
  `)
  return data.strapiHome.create_organization
}
