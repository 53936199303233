import { graphql, useStaticQuery } from 'gatsby'
import { HomeSection } from '../../interfaces/home'

export default (): HomeSection => {
  const data = useStaticQuery(graphql`
    {
      strapiHome {
        tool {
          button_navigate_en
          button_navigate_fr
          button_navigate_mg
          description_en
          description_fr
          description_mg
          id
          link
          title_en
          title_fr
          title_mg
          home_section_list {
            btn_label_en
            btn_label_fr
            btn_label_mg
            description_en
            description_fr
            description_mg
            id
            link
            title_en
            title_fr
            title_mg
            logo {
              publicURL
            }
          }
        }
      }
    }
  `)

  return data.strapiHome.tool
}
