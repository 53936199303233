import Box from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import 'swiper/swiper-bundle.css'
import FooterBanner from '../components/advertising/footerBanner'
import HeaderBanner from '../components/advertising/headerBanner'
import CreateOrganization from '../components/pages/home/CreateOrganization'
import HomeStatistic from '../components/pages/home/HomeStatistic'
import HomeStep from '../components/pages/home/HomeStep'
import HomeTool from '../components/pages/home/HomeTool'
import SEO from '../components/seo'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '@media (max-width: 960px)': {
      padding: `0 24px 0 24px !important`,
    },
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(10)}px 0 ${theme.spacing(10)}px`,
    },
    boxSizing: 'border-box',
    padding: `0 ${theme.spacing(19)}px 0 ${theme.spacing(19)}px`,
  },
}))

const IndexPage = () => {
  const classes = useStyles()

  return (
    <>
      <SEO title="Accueil" />
      <Box className={classes.container}>
        <CreateOrganization />
        <HomeStep />
        <HeaderBanner isTop={false} />
        <HomeTool />
        <HomeStatistic />
        <FooterBanner />
      </Box>
    </>
  )
}

export default IndexPage
