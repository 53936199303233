import Box from '@material-ui/core/Box'
import { useLocation } from '@reach/router'
import React, { useEffect, useState } from 'react'
import useAllFooterAdvertising from '../../../hooks/advertisingBanners/useAllFooterAdvertising'
import { getAdvertisingImageItems } from '../../../utils/advertisingBanner'
import PubCarousel from '../../common/pubCarousel'

const FooterBanner = () => {
  const data = useAllFooterAdvertising()
  const { desktop, mobile } = getAdvertisingImageItems(data)

  const [state, setState] = useState({
    isMobileView: false,
    isDrawerOpen: false,
  })

  const location = useLocation()
  const isHome = location.pathname.includes(
    process.env.GATSBY_PATH_PREFIX ?? '/',
  )
  // const isNotHowTo = !location.pathname.includes('howTo')
  // const shouldOnTop = isHome && isNotHowTo

  const displayBanner = isHome

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 960
        ? setState({ ...state, isMobileView: true })
        : setState({ ...state, isMobileView: false })
    }

    setResponsiveness()

    window.addEventListener('resize', () => setResponsiveness())
  }, [])

  return (
    <>
      {displayBanner ? (
        <>
          {state.isMobileView ? (
            <Box mb={4}>
              <PubCarousel width={320} height={100} imagesData={mobile} />
            </Box>
          ) : (
            <Box mb={4}>
              <PubCarousel width={970} height={150} imagesData={desktop} />
            </Box>
          )}
        </>
      ) : null}
    </>
  )
}

export default FooterBanner
