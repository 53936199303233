import React, { FC } from 'react'
import { Box, makeStyles, Theme, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { getInternationalizedValue } from '../../../../utils/common'
import { HomeSectionList } from '../../../../interfaces/home'
import { Link } from 'gatsby'
import TextMarkdown from '../../../customMarkdown/textMarkdown'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F2FDF5',
    borderRadius: 6,
    height: 348,
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
  },
  title: {
    color: '#111827',
    fontSize: 20,
    fontWeight: 600,
  },
  description: {
    fontSize: 16,
    fontWeight: 400,
    color: '#384252',
    textAlign: 'center',
  },
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: '#F9FAFB',
    padding: '8px 20px',
    textDecoration: 'none',
    borderRadius: 12,
    width: 'fit-content',
    fontSize: 14,
    marginTop: 30,
  },
}))

const ItemHomeTool: FC<{ item: HomeSectionList }> = ({ item }) => {
  const classes = useStyles()

  const { language } = useSelector((state: RootState) => state.app)

  const title = getInternationalizedValue(item, 'title', language)
  const description = getInternationalizedValue(item, 'description', language)
  const btnLabel = getInternationalizedValue(item, 'btn_label', language)

  return (
    <Box className={classes.container}>
      <img src={item.logo.publicURL} alt="logo tool" />
      <Typography component="h3" className={classes.title}>
        {title}
      </Typography>
      <Typography component="p" className={classes.description}>
        <TextMarkdown text={description} isTextJustify={false} />
      </Typography>
      <Link className={classes.link} to={item.link}>
        {btnLabel} &#x27A1;
      </Link>
    </Box>
  )
}

export default ItemHomeTool
