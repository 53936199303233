import React, { FC } from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    padding: '10px 30px',
    textDecoration: 'none',
    borderRadius: 12,
    width: 'fit-content',
  },
}))

const ButtonLink: FC<{ label: string; to: string }> = ({ label, to }) => {
  const classes = useStyles()
  return (
    <Link to={to} className={classes.link}>
      {label}
    </Link>
  )
}

export default ButtonLink
