import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import useHomeStep from '../../../../hooks/home/useHomeStep'
import { RootState } from '../../../../redux/store'
import { getInternationalizedValue } from '../../../../utils/common'
import ButtonLink from '../../../common/ButtonLink'
import TextMarkdown from '../../../customMarkdown/textMarkdown'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    marginTop: 50,
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 50,
  },
  title: {
    fontWeight: 700,
    fontSize: 50,
    color: '#494E5B',
    lineHeight: 1,
    textAlign: 'center',
    maxWidth: 906,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  image: {
    width: 450,
    [theme.breakpoints.down('sm')]: {
      width: 260,
    },
  },
  itemImage: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: 20,
    },
  },
  paragraph: {
    color: '#373A3C',
    fontSize: 25,
    marginBottom: 20,
    textAlign: 'justify',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  containerGrid: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  itemParagraph: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column ',
      alignItems: 'center',
    },
  },
}))

const HomeStep = () => {
  const classes = useStyles()

  const data = useHomeStep()

  const { language } = useSelector((state: RootState) => state.app)

  const title = getInternationalizedValue(data, 'title', language)
  const description = getInternationalizedValue(data, 'description', language)
  const btnLabel = getInternationalizedValue(data, 'button_navigate', language)

  return (
    <Box className={classes.container}>
      <Typography className={classes.title} component="h1">
        {title}
      </Typography>
      <Grid container={true} className={classes.containerGrid}>
        <Grid item={true} lg={6} md={12} sm={12} className={classes.itemImage}>
          <img
            className={classes.image}
            src={data.image_illustration.publicURL}
            alt="step image"
          />
        </Grid>
        <Grid
          item={true}
          lg={6}
          md={12}
          sm={12}
          className={classes.itemParagraph}
        >
          <Typography component="p" className={classes.paragraph}>
            <TextMarkdown text={description} />
          </Typography>
          <ButtonLink label={btnLabel} to={data.link} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default HomeStep
