import { Box, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import useHomeTool from '../../../../hooks/home/useHomeTool'
import { RootState } from '../../../../redux/store'
import { getInternationalizedValue } from '../../../../utils/common'
import ButtonLink from '../../../common/ButtonLink'
import Swiper from 'react-id-swiper'
import { SWIPER_PARAMS } from '../../../../constants/common'
import SwiperNavigation from '../../../libs/swiperNavigation'
import ItemHomeTool from './ItemHomeTool'
import TextMarkdown from '../../../customMarkdown/textMarkdown'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    color: '#494E5B',
    fontSize: 50,
    fontWeight: 700,
    textAlign: 'center',
    lineHeight: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  description: {
    fontSize: 25,
    margin: '30px 0',
    color: '#373A3C',
    textAlign: 'justify',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  containerSwiper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
}))

const HomeTool = () => {
  const classes = useStyles()

  const { language } = useSelector((state: RootState) => state.app)

  const data = useHomeTool()

  const title = getInternationalizedValue(data, 'title', language)
  const description = getInternationalizedValue(data, 'description', language)
  const btnLabel = getInternationalizedValue(data, 'button_navigate', language)

  const swiperRef = useRef<any>(null)

  const [isMediumScreen, setIsMediumScreen] = useState(false)

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1200
        ? setIsMediumScreen(true)
        : setIsMediumScreen(false)
    }

    setResponsiveness()

    window.addEventListener('resize', () => setResponsiveness())
  }, [])

  return (
    <Box className={classes.container}>
      <Typography className={classes.title} component="h1">
        {title}
      </Typography>
      <Typography component="p" className={classes.description}>
        <TextMarkdown text={description} />
      </Typography>
      <Box className={classes.containerSwiper}>
        <Swiper {...SWIPER_PARAMS} ref={swiperRef}>
          {data.home_section_list.map((item, index) => (
            <Box key={`item-tool-${index}`}>
              <ItemHomeTool item={item} />
            </Box>
          ))}
        </Swiper>
      </Box>
      {isMediumScreen ? <SwiperNavigation swiperRef={swiperRef} /> : null}
      <Box>
        <ButtonLink label={btnLabel} to={data.link} />
      </Box>
    </Box>
  )
}

export default HomeTool
