import { Box, makeStyles, Theme, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import useHomeCreateOrganization from '../../../../hooks/home/useHomeCreateOrganization'
import { RootState } from '../../../../redux/store'
import { getInternationalizedValue } from '../../../../utils/common'
import ButtonLink from '../../../common/ButtonLink'
import TextMarkdown from '../../../customMarkdown/textMarkdown'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '@media (max-width: 740px)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
  image: {
    width: 455,
    '@media (max-width: 740px)': {
      width: 300,
    },
  },
  containerTitle: {
    width: 500,
    '@media (max-width: 740px)': {
      marginBottom: 20,
      width: '100%',
    },
  },
  title: {
    fontWeight: 900,
    fontSize: 80,
    lineHeight: 1,
    color: '#22252D',
    [theme.breakpoints.down('sm')]: {
      fontSize: 50,
    },
  },
  description: {
    fontSize: 21,
    margin: '40px 0',
    color: '#494E5B',
    textAlign: 'justify',
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0',
    },
  },
}))

const CreateOrganization = () => {
  const classes = useStyles()

  const { language } = useSelector((state: RootState) => state.app)

  const data = useHomeCreateOrganization()

  const title = getInternationalizedValue(data, 'title', language)
  const description = getInternationalizedValue(data, 'description', language)
  const btnLabel = getInternationalizedValue(data, 'button_navigate', language)

  return (
    <Box className={classes.container}>
      <Box className={classes.containerTitle}>
        <Typography className={classes.title} component="h1">
          {title}
        </Typography>
        <Typography component="p" className={classes.description}>
          <TextMarkdown text={description} />
        </Typography>
        <ButtonLink label={btnLabel} to={data.link} />
      </Box>
      <Box>
        <img
          className={classes.image}
          src={data.image_illustration.publicURL}
          alt="create organization"
        />
      </Box>
    </Box>
  )
}

export default CreateOrganization
